import Cookie from "cookie";
import { useEffect, useState } from "react";
import { ServerInterface_isSlidUser } from "../serverInterface";

/**
 * Check if user is authenticated both in localStorage and in CookieStorage
 * @return boolean isAthenticated
 */
export const useIsAuthenticated = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const handleIsAuthenticated = async () => {
      const regex = /(?=.*CognitoIdentityServiceProvider)(?=.*idToken).*/;

      const getCognitoIdTokenFromLocalStorage = () => {
        const localStorageItems = { ...localStorage };

        let cognitoIdTokenKey = null;

        for (let key in localStorageItems) {
          if (regex.test(key)) cognitoIdTokenKey = key;
        }

        // @ts-ignore
        return localStorageItems[cognitoIdTokenKey];
      };

      const getCognitoIdTokenFromCookieStorage = () => {
        const cookies = document.cookie;
        if (!cookies) return;

        const parsedCookies: Object = Cookie.parse(cookies);
        let cognitoIdTokenKey = null;

        for (let key in parsedCookies) {
          if (regex.test(key)) cognitoIdTokenKey = key;
        }

        // @ts-ignore
        return parsedCookies[cognitoIdTokenKey];
      };

      const cognitoIdTokenFromLocalStorage = getCognitoIdTokenFromLocalStorage();
      const cognitoIdTokenFromCookie = getCognitoIdTokenFromCookieStorage();

      const cognitoIdToken = cognitoIdTokenFromLocalStorage ?? cognitoIdTokenFromCookie;

      if (!cognitoIdToken) return;

      const isSlidUser = await ServerInterface_isSlidUser({ cognitoIdToken });

      if (isSlidUser) setIsAuthenticated(true);
    };

    handleIsAuthenticated();
  }, []);

  return isAuthenticated;
};
