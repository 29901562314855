import { Typography17 } from "@slid/slid-ips";
import React from "react";
import { useTranslation } from "next-i18next";

const LoadingContainer = () => {
  const { t } = useTranslation("common");
  return (
    <div className={`main-loading-container d-flex flex-column justify-content-center align-items-center`}>
      <div>
        <img className={`logo`} src={`/logo/slid_logo.png`} alt={``} />
        <img className={`logo-text`} src={`/logo/slid_logo_text.png`} alt={``} />
      </div>
      <div className="spinner-border mt-3" role="status" />
      <Typography17 text={t("LoadingStatement")} color={"--gray15"} marginTop={"12px"} />
    </div>
  );
};

export default LoadingContainer;
