import React, { useEffect, useState } from "react";

import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useRouter } from "next/router";
import LoadingContainer from "src/components/containers/LoadingContainer";

import env from "@/config/env";
import { useIsAuthenticated } from "src/utils/customHook/useIsAuthenticated";
import { useAppSelector, useRemoteConfig } from "src/hooks";

const SLID_WEB_APP_URL = env.end_point_url.slid_web_app;
const SLID_NEW_LANDING_PAGE = env.currentEnv === "production" ? `https://home.slid.cc` : "https://phone-hear-293524.framer.app/";

const Home = () => {
  const router = useRouter();
  const { lang } = useAppSelector((state) => state.slidGlobal);
  const [stableVersions, setStableVersions] = useState<any>({});

  const isAuthenticated = useIsAuthenticated();
  const { remoteConfigValue, isLoading: isRemoteConfigFeching } = useRemoteConfig("versions");
  const version = stableVersions && stableVersions["slid_desktop_mac_version"] ? stableVersions["slid_desktop_mac_version"] : "3.1.2";

  useEffect(() => {
    if (isAuthenticated) router.push(`${SLID_WEB_APP_URL}`);
  }, []);

  useEffect(() => {
    if (isRemoteConfigFeching) return;
    setStableVersions(remoteConfigValue);
  }, [isRemoteConfigFeching]);

  const redirectToNewLanding = () => {
    const path = window.location.pathname;
    if (lang === "ko" || path.includes("ko")) window.location.assign(SLID_NEW_LANDING_PAGE);
    else window.location.assign(`${SLID_NEW_LANDING_PAGE}/home_EN`);
    return null;
  };

  if (typeof window === "undefined") return <LoadingContainer />;

  return redirectToNewLanding();
};

export async function getStaticProps({ locale }: any) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["Landing", "Footer", "Navigation"])),
    },
  };
}

export default Home;
